import React, { useEffect, useContext } from "react";

import GlobalContext from "../../context/GlobalContext";

const headerConfigDefault = {
  render: true,
  theme: "light",
  bgClass: "dynamic-sticky-bg",
  variant: "primary",
  align: "right",
  isFluid: true,
  button: "account", // profile, cart, cta, account, null
  buttonText: "Get started free", // profile, cart, cta, account, null
  reveal: true,
  paddingBottom: 10,
};

const footerConfigDefault = {
  render: true,
  brands: true,
  theme: "dark",
  style: "style1", //style1, style2, style3
};

const feedbackConfigDefault = {
  render: true
}

const PageWrapper = ({
  children,
  headerConfig = null,
  footerConfig = null,
  feedbackConfig = null,
  hideCookieConsentBar = false,
}) => {
  const gContext = useContext(GlobalContext);

  // hide cookie consent bar if demanded by page
  gContext.setVisibleCookieConsentBar(!hideCookieConsentBar)

  useEffect(() => {
    if (gContext.themeDark) {
      gContext.setHeader({
        ...headerConfigDefault,
        ...headerConfig,
        theme: "dark",
      });
      gContext.setFooter({
        ...footerConfigDefault,
        ...footerConfig,
        theme: "dark",
      });
      gContext.setFeedback({
        ...feedbackConfigDefault,
        ...feedbackConfig
      })
    } else {
      gContext.setHeader({ ...headerConfigDefault, ...headerConfig });
      gContext.setFooter({ ...footerConfigDefault, ...footerConfig });
      gContext.setFeedback({...feedbackConfigDefault, ...feedbackConfig})
    }
  }, [gContext.themeDark]);

  return <><div className="page-content">{children}</div></>;
};

export default PageWrapper;
